.dots-container {
    display: none;
}

.project {
    display: flex;
    flex-direction: column;
    gap: 100px;
    &--01 {
        background-image: linear-gradient(#061926 0%, #165C8C 100%);
        color: #FFFFFF;
    }
    &--02 {
        background-image: linear-gradient(119deg, #5019AC -0.02%, #361966 61.28%, #4907AE 87.35%, #4F02C2 110.42%);
        color: #FFFFFF;
    }
    &--03 {
        background-image: linear-gradient(117deg, #003177 3.84%, #00419D 139.53%);
        color: #FFFFFF;
    }
    &--04 {
        background-image: linear-gradient(180deg, #F2F4FF 0%, #F2F2F5 55.5%, #F4EBCA 74.5%);
        & .project-tag {
            border-color: #000000;
        }
    }
    &--05 {
        color: #FFFFFF;
        background-image: linear-gradient(0deg, #172E5C 0%, #172E5C 100%);
    }
    &-main-info {
        display: flex;
        flex-direction: column;
        gap: 100px;
    }

    &-intro {
        display: flex;
        flex-direction: column;
        gap: 48px;
    }
    &-header {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin: 0;

    }
    &-subtitle {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 4px;
        text-transform: uppercase;
        line-height: 1;
        font-family: $font-light;
    }
    &-title {
        font-size: 48px;
        margin-bottom: 0;
        text-transform: uppercase;
    }
    &-description {
        position: relative;
        margin: 0;
        padding-left: 180px;
        & p {
            font-size: 28px;
            font-weight: 100;
            & a {
                text-decoration: underline;
            }
        }
    }
    &-link {
        font-family: $font-regular;
    }
    &-tags {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;
    }
    &-tag {
        border: 1px solid #FFFFFF;
        border-radius: 50px;
        padding: 6px 12px 4px 12px;
        font-family: $font-light;
    }
    &-params {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &__digit {
            font-size: 80px;
            line-height: 80px;
            font-weight: 100;
            font-family: $font-extra-think;
        }
        &__desc {
            text-transform: uppercase;
            font-family: $font-light;
        }
        &__list {
            display: flex;
            gap: 56px;
        }
    }
    &-feature {
        display: flex;
        flex-direction: column;
        gap: 40px;
        &-img {
            &__grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 24px;
                &--four {
                    grid-template-columns: repeat(4, 1fr);
                }
                &--three {
                    grid-template-columns: repeat(2, 1fr);
                    & .project-feature-img:last-child {
                        grid-area: 2/1/2/3;
                    }
                }
                &--three-line {
                    grid-template-columns: repeat(3, 1fr);
                }
            }
        }
        &__description {
            font-weight: 100;
        }
    }
}

.img-parallax {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    background-repeat: no-repeat;
    background-attachment: fixed;
    z-index: 0;
    &--rebate-key {
        background-image: url('../../img/backgrounds/project05-bg.svg');
        background-position: top 30% right;

    }
    &--pixelfy {
        background-image: url('../../img/backgrounds/project04-bg.svg');
        background-position: top 25% center;
        background-size: contain;
    }
    &--picword {
        background-image: url('../../img/backgrounds/project02-bg.png');
        background-position: top 30% right;
    }
}
.img-bg-fixed {
    position: absolute;
    left: 0;
    bottom: auto;
    right: 0;
    width: 100%;
    height: 100%;
    background-position: top right;
    background-repeat: no-repeat;
    background-attachment: fixed;
    z-index: 0;
    &--elite-seller {
        background-image: url('../../img/backgrounds/project03-bg.svg');
    }
    &--acct {
        background-image: url('../../img/backgrounds/project01-bg.svg');
    }

}
