@charset "UTF-8";
/***
*
* iconfont.scss генерируется динамечески со списком классов для иконочного шрифта
* _iconfont-template.styl - шаблон для генерации файла iconfont.styl
*
***/
/* Генерация рандомного числа, для обнуления кеша при деплое на песок и прод */
@font-face {
  font-family: "iconfont";
  src: url("../fonts/iconfont/iconfont.eot");
  src: url("../fonts/iconfont/iconfont.eot?#iefix") format("eot"), url("../fonts/iconfont/iconfont.woff2") format("woff2"), url("../fonts/iconfont/iconfont.woff") format("woff"), url("../fonts/iconfont/iconfont.ttf") format("truetype"), url("../fonts/iconfont/iconfont.svg?#iconfont") format("svg");
}
[class^=icon-], [class*=" icon-"] {
  display: flex;
  justify-content: center;
  align-items: center;
}
[class^=icon-]::before, [class*=" icon-"]::before {
  font-family: "iconfont";
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
}

.icon-arrow-top::before {
  content: "\e001";
}

.icon-arrow::before {
  content: "\e002";
}

.icon-burger::before {
  content: "\e003";
}

.icon-close::before {
  content: "\e004";
}

.icon-dot::before {
  content: "\e005";
}

.icon-enter::before {
  content: "\e006";
}

.icon-pin::before {
  content: "\e007";
}

.icon-shift::before {
  content: "\e008";
}

.icon-facebook::before {
  content: "\e009";
}

.icon-instagram::before {
  content: "\e00a";
}

.icon-linkedin::before {
  content: "\e00b";
}

.icon-vimeo::before {
  content: "\e00c";
}

@font-face {
  font-family: "PF DinText Regular";
  src: url("../fonts/PFDinTextPro-Regular.eot");
  src: url("../fonts/PFDinTextPro-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/PFDinTextPro-Regular.woff2") format("woff2"), url("../fonts/PFDinTextPro-Regular.woff") format("woff"), url("../fonts/PFDinTextPro-Regular.ttf") format("truetype"), url("../fonts/PFDinTextPro-Regular.svg#PFDinTextPro-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "PF DinText Medium";
  src: url("../fonts/PFDinTextPro-Medium.eot");
  src: url("../fonts/PFDinTextPro-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/PFDinTextPro-Medium.woff2") format("woff2"), url("../fonts/PFDinTextPro-Medium.woff") format("woff"), url("../fonts/PFDinTextPro-Medium.ttf") format("truetype"), url("../fonts/PFDinTextPro-Medium.svg#PFDinTextPro-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "PF DinText Thin";
  src: url("../fonts/PFDinTextPro-Thin.eot");
  src: url("../fonts/PFDinTextPro-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/PFDinTextPro-Thin.woff2") format("woff2"), url("../fonts/PFDinTextPro-Thin.woff") format("woff"), url("../fonts/PFDinTextPro-Thin.ttf") format("truetype"), url("../fonts/PFDinTextPro-Thin.svg#PFDinTextPro-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "PF DinText Light";
  src: url("../fonts/PFDinTextPro-Light.eot");
  src: url("../fonts/PFDinTextPro-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/PFDinTextPro-Light.woff2") format("woff2"), url("../fonts/PFDinTextPro-Light.woff") format("woff"), url("../fonts/PFDinTextPro-Light.ttf") format("truetype"), url("../fonts/PFDinTextPro-Light.svg#PFDinTextPro-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "PF DinText ExtraThin";
  src: url("../fonts/PFDinTextPro-ExtraThin.eot");
  src: url("../fonts/PFDinTextPro-ExtraThin.eot?#iefix") format("embedded-opentype"), url("../fonts/PFDinTextPro-ExtraThin.woff2") format("woff2"), url("../fonts/PFDinTextPro-ExtraThin.woff") format("woff"), url("../fonts/PFDinTextPro-ExtraThin.ttf") format("truetype"), url("../fonts/PFDinTextPro-ExtraThin.svg#PFDinTextPro-ExtraThin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
/* arrow */
/* text overflow ellipsis */
html,
body {
  overflow-x: hidden;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  min-height: 100vh;
  text-decoration-skip: none;
}

iframe {
  border: 0;
}

main {
  display: block;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 40px;
  padding-left: 32px;
}

li {
  line-height: 1.5;
  font-family: "PF DinText Thin", sans-serif;
  font-size: 24px;
  margin-bottom: 0;
}

dl {
  margin-top: 0;
  margin-bottom: 0;
}

dd {
  margin-left: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: inherit;
}

blockquote {
  margin: 0;
  padding: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

sup {
  position: relative;
  top: -0.5em;
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
}

strong {
  font-weight: bold;
}

figure {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

img {
  max-width: 100%;
  height: auto;
  border: 0;
  vertical-align: middle;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}

a,
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

button {
  overflow: visible;
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  text-align: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  font: inherit;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
}

button,
[type=text],
[type=password],
[type=submit],
[type=search],
[type=email],
[type=tel],
textarea {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

::-moz-focus-inner {
  padding: 0;
  border: 0;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

th {
  vertical-align: middle;
}

td {
  vertical-align: middle;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  outline: 0;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

input[type=radio],
input[type=checkbox] {
  margin: 0;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: transparent;
  border: 0;
  border-bottom: 0 solid #3d4047;
  -webkit-text-fill-color: #707585;
  transition: background-color 5000s ease-in-out 0s;
}

input:-internal-autofill-selected,
input:-internal-autofill-previewed {
  color: #221603 !important;
  background-color: rgba(219, 255, 0, 0.8) !important;
  -webkit-box-shadow: 0 0 0 1000px rgba(219, 255, 0, 0.9) inset !important;
  -webkit-text-fill-color: #000000 !important;
}

html,
body,
.mobile-nav {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar,
.mobile-nav::-webkit-scrollbar {
  display: none;
}

body {
  color: #221603;
  transition: color 0.4s;
  background-color: #ffffff;
  z-index: 0;
  overflow-x: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE и Edge */
}

.main {
  position: relative;
  z-index: 1;
}

.container {
  position: relative;
  margin: 0 auto;
  padding: 0 24px;
  max-width: 1288px;
  width: 100%;
}
.container--fixed {
  position: fixed;
  z-index: 11;
  bottom: 24px;
  left: 0;
  right: 0;
  max-width: 1440px;
}

::-webkit-scrollbar {
  display: none;
}

#slogan-text {
  overflow: hidden;
  white-space: nowrap;
  border-right: 2px solid;
  animation: blink 0.75s step-end infinite;
  font-weight: 400;
  display: flex;
  height: 20px;
}

@keyframes blink {
  from {
    border-color: transparent;
  }
  to {
    border-color: black;
  }
}
html {
  font-size: 16px;
  font-family: "PF DinText Regular", sans-serif;
}

h1,
h2,
h3,
h5 {
  line-height: normal;
  user-select: none;
  margin-bottom: 24px;
  font-family: "PF DinText Thin", sans-serif;
  font-weight: 100;
}

h1 {
  display: block;
  width: 100%;
  font-size: 56px;
}

h2 {
  display: block;
  width: 100%;
  font-size: 40px;
  line-height: 1.1;
}

h3 {
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 16px;
}

h4 {
  font-family: "PF DinText Light", sans-serif;
  font-size: 24px;
  margin-bottom: 24px;
}

h5 {
  font-size: 16px;
  text-transform: uppercase;
}

h6 {
  font-size: 16px;
  text-transform: uppercase;
  line-height: 1.5;
}

p {
  font-size: 24px;
  margin-bottom: 16px;
  line-height: 1.5;
  font-family: "PF DinText Thin", sans-serif;
}
p:last-child {
  margin-bottom: 0;
}

li,
a {
  user-select: none;
}

b {
  font-family: "PF DinText Medium", sans-serif;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  line-height: 40px;
  width: auto;
  font-size: 22px;
  box-sizing: border-box;
  text-align: center;
  user-select: none;
  padding: 0 20px;
  color: #221603;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s;
  cursor: pointer;
  border-radius: 32px;
}
.button i {
  font-size: 14px;
  margin-left: 10px;
  text-shadow: none;
}
.button:hover {
  transition: all 0.2s;
}
.button--submit {
  font-size: 24px;
  text-transform: uppercase;
  text-decoration: underline;
  color: #221603;
}
.button--submit:hover {
  text-decoration: none;
}
.button--s1 {
  width: 260px;
  max-width: 100%;
}
.button--s2 {
  width: auto;
  min-width: 280px;
  padding: 0 26px;
}
.button--t1 {
  color: #FFFFFF;
  background: #F66F4D;
  border: 4px solid transparent;
}
.button--t1:hover {
  border: 4px solid #FFD2C7;
}
.button--t2 {
  border: 1px solid #260303;
}
.button--t2:hover {
  border: 4px solid #DDDDDD;
}

.form {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}
.form input[type=text],
.form input[type=password],
.form input[type=email],
.form input[type=tel],
.form input[type=search],
.form input[type=file],
.form input[type=number],
.form textarea {
  width: 100%;
  height: 64px;
  padding: 20px 0;
  font-size: 16px;
  line-height: 1.5;
  color: #221603;
  background: transparent;
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  transition: all 0.4s;
  text-overflow: ellipsis;
}
.form input[type=text]::placeholder,
.form input[type=password]::placeholder,
.form input[type=email]::placeholder,
.form input[type=tel]::placeholder,
.form input[type=search]::placeholder,
.form input[type=file]::placeholder,
.form input[type=number]::placeholder,
.form textarea::placeholder {
  transition: all 0.5s;
  text-overflow: ellipsis;
  color: #8DA403;
}
.form input[type=text]:focus::placeholder,
.form input[type=password]:focus::placeholder,
.form input[type=email]:focus::placeholder,
.form input[type=tel]:focus::placeholder,
.form input[type=search]:focus::placeholder,
.form input[type=file]:focus::placeholder,
.form input[type=number]:focus::placeholder,
.form textarea:focus::placeholder {
  opacity: 0;
}
.form input[type=text]:focus,
.form input[type=password]:focus,
.form input[type=email]:focus,
.form input[type=tel]:focus,
.form input[type=search]:focus,
.form input[type=file]:focus,
.form input[type=number]:focus,
.form textarea:focus {
  border: 0;
}
.form textarea {
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding: 20px 20px 20px 0;
  border-radius: 0;
  line-height: 1.5;
  overflow: auto;
  box-sizing: border-box;
  resize: none;
  font-family: "PF DinText Regular", sans-serif;
}
.form .form-input {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 24px;
  width: 100%;
  padding-left: 48px;
  border-bottom: solid 1px rgba(56, 56, 56, 0.4);
}
.form .form-input.error input {
  border-color: #DF2D14;
}
.form .form-input--textarea {
  align-items: flex-start;
}
.form .form-input--textarea .form-label {
  padding-top: 24px;
}
.form .form-submit {
  margin-top: 32px;
  text-align: right;
}
.form .form-label {
  font-family: "PF DinText Regular", sans-serif;
  font-size: 18px;
  width: 80px;
}
.form .form__input input[type=text]:disabled,
.form .form__input input[type=password]:disabled,
.form .form__input input[type=email]:disabled,
.form .form__input input[type=tel]:disabled,
.form .form__input textarea:disabled {
  color: #9FA4BC;
  border-color: #9FA4BC;
  cursor: default;
}
.form input::-webkit-outer-spin-button,
.form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form input[type=number] {
  -moz-appearance: textfield;
}

/*!
 * fullPage 4.0.22
 * https://github.com/alvarotrigo/fullPage.js
 *
 * @license GPLv3 for open source use only
 * or Fullpage Commercial License for commercial use
 * http://alvarotrigo.com/fullPage/pricing/
 *
 * Copyright (C) 2021 http://alvarotrigo.com/fullPage - A project by Alvaro Trigo
 */
html.fp-enabled,
.fp-enabled body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  /*Avoid flicker on slides transitions for mobile phones #336 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.fp-section {
  position: relative;
  -webkit-box-sizing: border-box; /* Safari<=5 Android<=3 */
  -moz-box-sizing: border-box; /* <=28 */
  box-sizing: border-box;
  height: 100%;
  display: block;
}

.fp-slide {
  float: left;
}

.fp-slide, .fp-slidesContainer {
  height: 100%;
  display: block;
}

.fp-slides {
  z-index: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 0.3s ease-out; /* Safari<=6 Android<=4.3 */
  transition: all 0.3s ease-out;
}

.fp-table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.fp-slidesContainer {
  float: left;
  position: relative;
}

.fp-controlArrow {
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
  position: absolute;
  z-index: 4;
  top: 50%;
  cursor: pointer;
  margin-top: -38px;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.fp-prev {
  left: 15px;
}

.fp-next {
  right: 15px;
}

.fp-arrow {
  width: 0;
  height: 0;
  border-style: solid;
}

.fp-arrow.fp-prev {
  border-width: 38.5px 34px 38.5px 0;
  border-color: transparent #fff transparent transparent;
}

.fp-arrow.fp-next {
  border-width: 38.5px 0 38.5px 34px;
  border-color: transparent transparent transparent #fff;
}

.fp-notransition {
  -webkit-transition: none !important;
  transition: none !important;
}

#fp-nav {
  position: fixed;
  z-index: 100;
  top: 50%;
  opacity: 1;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translate3d(0, -50%, 0);
}

#fp-nav.fp-right {
  right: 17px;
}

#fp-nav.fp-left {
  left: 17px;
}

.fp-slidesNav {
  position: absolute;
  z-index: 4;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  left: 0 !important;
  right: 0;
  margin: 0 auto !important;
}

.fp-slidesNav.fp-bottom {
  bottom: 17px;
}

.fp-slidesNav.fp-top {
  top: 17px;
}

#fp-nav ul,
.fp-slidesNav ul {
  margin: 0;
  padding: 0;
}

#fp-nav ul li,
.fp-slidesNav ul li {
  display: block;
  width: 14px;
  height: 13px;
  margin: 7px;
  position: relative;
}

.fp-slidesNav ul li {
  display: inline-block;
}

#fp-nav ul li a,
.fp-slidesNav ul li a {
  display: block;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
}

#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li:hover a.active span {
  height: 12px;
  width: 12px;
  margin: -6px 0 0 -6px;
  border-radius: 100%;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  height: 4px;
  width: 4px;
  border: 0;
  background: #333;
  left: 50%;
  top: 50%;
  margin: -2px 0 0 -2px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span {
  width: 10px;
  height: 10px;
  margin: -5px 0px 0px -5px;
}

#fp-nav ul li .fp-tooltip {
  position: absolute;
  top: -2px;
  color: #fff;
  font-size: 14px;
  font-family: arial, helvetica, sans-serif;
  white-space: nowrap;
  max-width: 220px;
  overflow: hidden;
  display: block;
  opacity: 0;
  width: 0;
  cursor: pointer;
}

#fp-nav ul li:hover .fp-tooltip,
#fp-nav.fp-show-active a.active + .fp-tooltip {
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  width: auto;
  opacity: 1;
}

#fp-nav ul li .fp-tooltip.fp-right {
  right: 20px;
}

#fp-nav ul li .fp-tooltip.fp-left {
  left: 20px;
}

.fp-auto-height.fp-section,
.fp-auto-height .fp-slide {
  height: auto !important;
}

.fp-responsive .fp-is-overflow.fp-section {
  height: auto !important;
}

/* Used with autoScrolling: false */
.fp-scrollable.fp-responsive .fp-is-overflow.fp-section,
.fp-scrollable .fp-section,
.fp-scrollable .fp-slide {
  /* Fallback for browsers that do not support Custom Properties */
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.fp-scrollable.fp-responsive .fp-is-overflow.fp-section:not(.fp-auto-height):not([data-percentage]),
.fp-scrollable .fp-section:not(.fp-auto-height):not([data-percentage]),
.fp-scrollable .fp-slide:not(.fp-auto-height):not([data-percentage]) {
  /* Fallback for browsers that do not support Custom Properties */
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}

/* Disabling vertical centering on scrollable elements */
.fp-overflow {
  justify-content: flex-start;
  max-height: 100vh;
}

/* No scrollable when using auto-height */
.fp-scrollable .fp-auto-height .fp-overflow {
  max-height: none;
}

.fp-is-overflow .fp-overflow.fp-auto-height-responsive,
.fp-is-overflow .fp-overflow.fp-auto-height,
.fp-is-overflow > .fp-overflow {
  overflow-y: auto;
}

.fp-overflow {
  outline: none;
}

.fp-overflow.fp-table {
  display: block;
}

.fp-responsive .fp-auto-height-responsive.fp-section,
.fp-responsive .fp-auto-height-responsive .fp-slide {
  height: auto !important;
  min-height: auto !important;
}

/*Only display content to screen readers*/
.fp-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */
.fp-scroll-mac .fp-overflow::-webkit-scrollbar {
  background-color: transparent;
  width: 9px;
}

.fp-scroll-mac .fp-overflow::-webkit-scrollbar-track {
  background-color: transparent;
}

.fp-scroll-mac .fp-overflow::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 16px;
  border: 4px solid transparent;
}

.fp-warning,
.fp-watermark {
  z-index: 9999999;
  position: absolute;
  bottom: 0;
}

.fp-warning,
.fp-watermark a {
  text-decoration: none;
  color: #000;
  background: rgba(255, 255, 255, 0.6);
  padding: 5px 8px;
  font-size: 14px;
  font-family: arial;
  color: black;
  display: inline-block;
  border-radius: 3px;
  margin: 12px;
}

.fp-noscroll .fp-overflow {
  overflow: hidden;
}

@media all and (max-width: 1800px) {
  .container {
    max-width: 968px;
  }
  .feedback-form {
    grid-template-columns: 310px 600px;
  }
}
@media all and (max-width: 1340px) {
  .container {
    max-width: 820px;
  }
  .project-params__digit {
    font-size: 76px;
  }
  .project-tags {
    font-size: 14px;
  }
  .feedback-form {
    grid-template-columns: 200px 600px;
  }
}
@media all and (max-width: 1200px) {
  .header-info, .header-nav, .header-contacts, .page-nav, .footer-links {
    display: none;
  }
  .container {
    padding: 0 16px;
  }
  .fp-viewing-anchor3 .logo-text, .fp-viewing-anchor4 .logo-text, .fp-viewing-anchor5 .logo-text, .fp-viewing-anchor6 .logo-text, .fp-viewing-anchor7 .logo-text, .fp-viewing-anchor8 .logo-text {
    flex-direction: row;
    gap: 4px;
    line-height: 25px;
  }
  .fp-viewing-anchor2 .location, .fp-viewing-anchor3 .location, .fp-viewing-anchor4 .location, .fp-viewing-anchor5 .location, .fp-viewing-anchor6 .location, .fp-viewing-anchor7 .location, .fp-viewing-anchor8 .location {
    display: none;
  }
  .footer-row .copyright {
    display: none;
  }
  .footer .socials {
    display: none;
  }
  .about-title {
    margin-bottom: 40px;
    font-size: 26px;
  }
  .about-description {
    padding-left: 74px;
  }
  .about-description p {
    font-size: 24px;
  }
  .about-description__rows {
    grid-gap: 20px;
  }
  .about-description__row p {
    font-size: 18px;
  }
  .section-content {
    padding-top: 100px;
  }
  .section-content.section-content--00 {
    padding: 0;
  }
  .scroll-down {
    width: 100%;
    bottom: 80px;
  }
  .project {
    gap: 60px;
  }
  .project-main-info {
    gap: 12px;
  }
  .project-title {
    font-size: 28px;
  }
  .project-description {
    padding-left: 74px;
    margin-bottom: 20px;
  }
  .project-description p {
    font-size: 20px;
  }
  .project-feature {
    gap: 8px;
  }
  .project-feature .project-title {
    font-size: 24px;
  }
  .project-feature__description {
    margin-bottom: 8px;
  }
  .project-feature__description p {
    font-size: 20px;
  }
  .project-feature__description ul li {
    font-size: 20px;
  }
  .feedback-form {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 0;
  }
  .feedback-form__notice p {
    display: none;
  }
  .feedback-form__left {
    padding: 24px;
    gap: 40px;
  }
  .feedback-form__right {
    padding: 24px;
    border-left: none;
    border-top: solid 1px rgba(56, 56, 56, 0.4);
  }
  .feedback-form__header {
    padding: 0;
    flex-direction: column;
    gap: 12px;
  }
  .feedback-form__header h3 {
    margin-bottom: 0;
  }
  .form .form-input {
    padding-left: 0;
  }
  .footer {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .back-to-top {
    margin-bottom: 0;
  }
  .project-params__list {
    overflow: auto;
  }
  .project-params {
    flex-shrink: 0;
  }
  .feedback-form-contacts .header-nav {
    display: flex;
  }
  .mobile-nav__opener {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    font-size: 20px;
  }
}
@media all and (max-width: 680px) {
  .header {
    grid-template-columns: repeat(4, 1fr);
  }
  .logo-mobile {
    opacity: 1;
    visibility: visible;
    margin-left: 16px;
  }
  .mobile .logo, .mobile .location {
    opacity: 0;
    visibility: hidden;
  }
  .mobile.feedback-section .logo.logo-feedback-section {
    opacity: 1;
    visibility: visible;
  }
  .scroll-down {
    position: fixed;
    bottom: calc(var(--vh, 1vh) * 20);
  }
  .section-content--02 {
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    justify-content: flex-start;
    padding-top: 24px;
    height: 100%;
    max-height: unset;
    padding-bottom: 100px;
  }
  .section-content--02 .logo-mobile {
    position: unset;
    margin-left: 16px;
  }
  .project-img__wrapper {
    position: relative;
    height: 280px;
  }
  .project-img__wrapper .project-feature-img__grid {
    position: absolute;
    left: -16px;
    right: -16px;
    padding: 0 16px;
    overflow: auto;
    grid-gap: 12px;
  }
  .project-img__wrapper .project-feature-img__grid--row {
    grid-template-columns: repeat(4, 1fr);
  }
  .project-img__wrapper .project-feature-img__grid--four .project-feature-img {
    width: 136px;
  }
  .project-img__wrapper .project-feature-img__grid--three-line .project-feature-img {
    width: 136px;
  }
  .project-img__wrapper .project-feature-img {
    width: 280px;
  }
  .about-description__wrapper {
    position: relative;
    height: 90px;
  }
  .about-description__wrapper .about-description__rows {
    position: absolute;
    left: -16px;
    right: -16px;
    display: grid;
    padding: 0 16px;
    overflow: auto;
    grid-gap: 12px;
  }
  .about-description__wrapper .about-description__row {
    width: 280px;
  }
  .clients-list__wrapper {
    position: relative;
    height: 40px;
  }
  .clients-list__wrapper .clients-list {
    position: absolute;
    left: -16px;
    right: -16px;
    display: grid;
    grid-template-columns: repeat(12, 60px);
    justify-items: center;
    padding: 0 16px;
    overflow: auto;
    grid-gap: 12px;
  }
  .section-content--feedback {
    background-position: -60px center;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 32px;
    padding-top: 24px;
    padding-bottom: 86px;
  }
  .section-page {
    padding: 60px 0;
  }
  .page-title {
    font-size: 26px;
  }
  .page-description {
    padding-left: 74px;
  }
  .page-description p {
    font-size: 20px;
  }
  #logo {
    width: 130px;
    height: 56px;
  }
  .error-message {
    margin-left: 100px;
  }
  .img-parallax--pixelfy {
    background-size: 130%;
    background-position: top 43% center;
  }
  .img-parallax--rebate-key {
    background-size: 85%;
    background-position: top 47% right;
  }
  .img-parallax--picword {
    background-size: 90%;
    background-position: top 50% right;
  }
  .form textarea {
    min-height: 112px;
  }
  .feedback-form-description h3 {
    font-size: 48px;
    line-height: 1;
  }
  .feedback-form-description p {
    font-size: 28px;
    line-height: 1;
  }
  .footer-row .back-to-top {
    display: none;
  }
  .cookies__wrapper {
    width: auto;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 16px;
  }
  .static-page .location, .static-page .logo {
    display: none;
  }
  .page-text {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .page-text-footer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }
  .page-text-footer .copyright {
    display: flex;
  }
}
@media all and (max-width: 390px) {
  .dots-container {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
  }
  .dots-container .dot {
    width: 8px;
    height: 8px;
    border-radius: 6px;
    max-width: 30px;
    background-color: #CF72FE;
    transition: background-color 0.5s;
  }
  .dots-container .dot.active {
    background-color: #FFFFFF;
    transition: background-color 0.5s;
  }
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-end;
  padding: 24px;
}
.footer-links {
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
}
.footer-links span {
  font-family: "PF DinText Regular", sans-serif;
}
.footer-links a {
  font-family: "PF DinText Thin", sans-serif;
}
.footer-socials .socials {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: fit-content;
  margin-left: auto;
}
.footer-socials .socials__link {
  display: flex;
  font-size: 24px;
}

.copyright {
  display: flex;
  flex-direction: column;
  gap: 10px;
  opacity: 0;
  visibility: hidden;
  transition: all 800ms ease;
}
.copyright-links {
  display: flex;
  gap: 24px;
}
.copyright-link {
  text-transform: uppercase;
  text-decoration: underline;
  font-family: "PF DinText Regular", sans-serif;
}
.copyright-link:hover {
  text-decoration: none;
}
.copyright-text {
  font-family: "PF DinText Thin", sans-serif;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr 460px 460px 1fr;
  padding: 24px;
}
.header-download {
  opacity: 1;
  visibility: visible;
  transition: all 800ms ease;
}
.header-info {
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  line-height: 1.2;
  opacity: 1;
  visibility: visible;
  transition: all 800ms ease;
  font-size: 16px;
}
.header-info .word {
  font-family: "PF DinText Regular", sans-serif;
}
.header-info span {
  font-family: "PF DinText Thin", sans-serif;
}
.header-nav {
  display: flex;
  gap: 2px;
  text-transform: uppercase;
}
.header-nav span {
  font-family: "PF DinText Thin", sans-serif;
}
.header-link {
  text-decoration: underline;
  font-family: "PF DinText Regular", sans-serif;
}
.header-link:hover {
  text-decoration: none;
}
.header-contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
}

.location {
  position: fixed;
  left: 24px;
  top: 50%;
  transform: translateY(-50%) translateX(-50%) rotate(-90deg);
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease-in-out;
}
.location a {
  font-family: "PF DinText Regular", sans-serif;
  text-transform: uppercase;
}
.location i {
  font-size: 24px;
}

.page-nav {
  position: fixed;
  right: 24px;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);
  font-size: 14px;
  text-transform: uppercase;
  text-align: right;
  font-family: "PF DinText Regular", sans-serif;
}

.page-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  font-family: "PF DinText Regular", sans-serif;
}

.project-menu {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 0;
  margin-bottom: 0;
}
.project-menu li {
  font-size: 14px;
  text-transform: uppercase;
  text-align: right;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  line-height: 1;
  height: 16px;
}

.project-link a {
  display: flex;
  align-items: center;
  gap: 4px;
}
.project-link a i {
  font-size: 6px;
  line-height: 16px;
}
.project-link a span {
  opacity: 0;
  padding-top: 4px;
}
.project-link a.active span {
  opacity: 1;
}
.project-link a.active i {
  display: none;
}
.project-link a:hover span {
  opacity: 1;
}

.back-to-top {
  display: flex;
  align-items: center;
  gap: 4px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
}
.back-to-top span {
  font-family: "PF DinText Regular", sans-serif;
  text-transform: uppercase;
  text-decoration: underline;
}
.back-to-top:hover span {
  text-decoration: none;
}
.back-to-top--mobile {
  margin-left: 16px;
}

.dark .header, .dark .footer, .dark .location, .dark .page-nav {
  color: #FFFFFF;
  opacity: 0.6;
}
.dark .header-info, .dark .header-download {
  opacity: 0;
  visibility: hidden;
}

.hide-elements .header-info, .hide-elements .header-download {
  opacity: 0;
  visibility: hidden;
}

.feedback-section .back-to-top, .feedback-section .copyright {
  opacity: 1;
  visibility: visible;
}

.fullscreen-image {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: #FFFF92;
}

.background-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.background-img--first {
  z-index: 1;
}
.background-img--two {
  z-index: 0;
}

.background-img.current {
  opacity: 1;
}

.scroll-down {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  margin-bottom: 24px;
  z-index: 1;
}
.scroll-down span {
  font-family: "PF DinText Regular", sans-serif;
  text-transform: uppercase;
}
.scroll-down__dot {
  position: relative;
  width: 1px;
  height: 50px;
  background-color: #221603;
}
.scroll-down__dot:before {
  content: "";
  position: absolute;
  left: -10px;
  width: 21px;
  height: 21px;
  border-radius: 10px;
  background-color: #221603;
  animation: scrollDown 2.5s ease infinite;
}

@keyframes scrollDown {
  0% {
    transform: translateY(5px);
    opacity: 0;
  }
  50% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(25px);
    opacity: 0;
  }
}
.logo {
  margin-right: auto;
  display: block;
  transition: opacity 0.2s ease-in-out;
}
.logo .logo-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  text-transform: uppercase;
  position: relative;
}
.logo .text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform 0.8s ease-out;
  font-family: "PF DinText Regular", sans-serif;
  line-height: 1;
}
.logo .text.new-text {
  transform: translateY(100%);
}
.logo .text.old-text {
  transform: translateY(-100%);
}
.logo .line {
  display: flex;
  height: 16px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.logo-mobile {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.8s ease-out;
}
.logo-mobile .logo-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  text-transform: uppercase;
  position: relative;
}
.logo-mobile .logo-line {
  display: flex;
  height: 16px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.cookies__wrapper {
  position: fixed;
  bottom: 24px;
  right: 80px;
  z-index: 11;
  display: none;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  padding: 16px 16px 16px 48px;
  width: 380px;
  background: #FFFFFF;
}
.cookies__wrapper.show {
  display: flex;
}
.cookies-close {
  display: block;
  text-transform: uppercase;
  text-align: right;
  line-height: 1;
  font-weight: 400;
}
.cookies__message {
  width: 100%;
  font-weight: 300;
  font-size: 18px;
}
.cookies__message a {
  text-decoration: underline;
}
.cookies__message a:hover {
  text-decoration: none;
}

.section-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  overflow-x: hidden;
}
.section-content-img {
  width: 100%;
  height: 100%;
}
.section-content-title {
  text-transform: uppercase;
}
.section-content--00 {
  height: 100vh;
  overflow: hidden;
  padding: 0;
}
.section-content--01 {
  height: 100vh;
  overflow: hidden;
  padding: 0;
}
.section-content--02 {
  min-height: 100vh;
  max-height: 1600px;
  padding-top: 160px;
}
.section-content--feedback {
  min-height: 100vh;
  overflow: hidden;
  background-image: url("../../img/backgrounds/main01.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.clients-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.slogan {
  position: relative;
  width: 100%;
  height: 100%;
}
.slogan-container {
  position: relative;
  width: 200px;
  height: 20px;
  overflow: hidden;
}

.word {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  opacity: 0;
  transform: translateY(100%);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.word.active {
  opacity: 1;
  transform: translateY(0);
}

.word.exit {
  opacity: 0;
  transform: translateY(-100%);
}

.section1 {
  background-color: #FFFF92;
}

.section {
  position: relative;
  height: 100%;
}
.section--divider {
  position: relative;
  height: 100px;
}

.dots-container {
  display: none;
}

.project {
  display: flex;
  flex-direction: column;
  gap: 100px;
}
.project--01 {
  background-image: linear-gradient(#061926 0%, #165C8C 100%);
  color: #FFFFFF;
}
.project--02 {
  background-image: linear-gradient(119deg, #5019AC -0.02%, #361966 61.28%, #4907AE 87.35%, #4F02C2 110.42%);
  color: #FFFFFF;
}
.project--03 {
  background-image: linear-gradient(117deg, #003177 3.84%, #00419D 139.53%);
  color: #FFFFFF;
}
.project--04 {
  background-image: linear-gradient(180deg, #F2F4FF 0%, #F2F2F5 55.5%, #F4EBCA 74.5%);
}
.project--04 .project-tag {
  border-color: #000000;
}
.project--05 {
  color: #FFFFFF;
  background-image: linear-gradient(0deg, #172E5C 0%, #172E5C 100%);
}
.project-main-info {
  display: flex;
  flex-direction: column;
  gap: 100px;
}
.project-intro {
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.project-header {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0;
}
.project-subtitle {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  text-transform: uppercase;
  line-height: 1;
  font-family: "PF DinText Light", sans-serif;
}
.project-title {
  font-size: 48px;
  margin-bottom: 0;
  text-transform: uppercase;
}
.project-description {
  position: relative;
  margin: 0;
  padding-left: 180px;
}
.project-description p {
  font-size: 28px;
  font-weight: 100;
}
.project-description p a {
  text-decoration: underline;
}
.project-link {
  font-family: "PF DinText Regular", sans-serif;
}
.project-tags {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}
.project-tag {
  border: 1px solid #FFFFFF;
  border-radius: 50px;
  padding: 6px 12px 4px 12px;
  font-family: "PF DinText Light", sans-serif;
}
.project-params {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.project-params__digit {
  font-size: 80px;
  line-height: 80px;
  font-weight: 100;
  font-family: "PF DinText ExtraThin", sans-serif;
}
.project-params__desc {
  text-transform: uppercase;
  font-family: "PF DinText Light", sans-serif;
}
.project-params__list {
  display: flex;
  gap: 56px;
}
.project-feature {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.project-feature-img__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}
.project-feature-img__grid--four {
  grid-template-columns: repeat(4, 1fr);
}
.project-feature-img__grid--three {
  grid-template-columns: repeat(2, 1fr);
}
.project-feature-img__grid--three .project-feature-img:last-child {
  grid-area: 2/1/2/3;
}
.project-feature-img__grid--three-line {
  grid-template-columns: repeat(3, 1fr);
}
.project-feature__description {
  font-weight: 100;
}

.img-parallax {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: 0;
}
.img-parallax--rebate-key {
  background-image: url("../../img/backgrounds/project05-bg.svg");
  background-position: top 30% right;
}
.img-parallax--pixelfy {
  background-image: url("../../img/backgrounds/project04-bg.svg");
  background-position: top 25% center;
  background-size: contain;
}
.img-parallax--picword {
  background-image: url("../../img/backgrounds/project02-bg.png");
  background-position: top 30% right;
}

.img-bg-fixed {
  position: absolute;
  left: 0;
  bottom: auto;
  right: 0;
  width: 100%;
  height: 100%;
  background-position: top right;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: 0;
}
.img-bg-fixed--elite-seller {
  background-image: url("../../img/backgrounds/project03-bg.svg");
}
.img-bg-fixed--acct {
  background-image: url("../../img/backgrounds/project01-bg.svg");
}

.about {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.about-title {
  position: relative;
  font-size: 48px;
  text-transform: uppercase;
  margin-bottom: 40px;
}
.about-description {
  position: relative;
  padding-left: 180px;
}
.about-description p {
  font-size: 28px;
}
.about-description__rows {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 48px;
}

.animated {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.leave {
  opacity: 0 !important;
  transform: translateY(-150px) !important;
}

.leave-back {
  opacity: 0 !important;
  transform: translateY(150px) !important;
}

.feedback-form {
  position: relative;
  padding: 48px;
  z-index: 1;
  display: grid;
  grid-template-columns: 310px 1fr;
  grid-gap: 60px;
  transform: translateX(150%);
}
.feedback-form .error, .feedback-form .error input::placeholder, .feedback-form .error input, .feedback-form .error textarea, .feedback-form .error textarea::placeholder {
  color: #FF0000 !important;
}
.feedback-form:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: -100%;
  z-index: -1;
  background-color: rgba(219, 255, 0, 0.67);
  backdrop-filter: blur(15px);
}
.feedback-form__header {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  padding: 0 48px;
}
.feedback-form__header h3 {
  font-family: "PF DinText Regular", sans-serif;
  text-transform: uppercase;
}
.feedback-form__header span {
  font-size: 20px;
  font-weight: 100;
}
.feedback-form-description h3 {
  font-family: "PF DinText Regular", sans-serif;
  font-size: 60px;
  text-transform: uppercase;
}
.feedback-form-description p {
  font-family: "PF DinText Light", sans-serif;
  font-size: 32px;
  text-transform: uppercase;
}
.feedback-form-contacts {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 24px;
}
.feedback-form-contacts p {
  font-weight: 100;
}
.feedback-form__left {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.feedback-form__right {
  border-left: solid 1px rgba(56, 56, 56, 0.4);
}
.feedback-form__notice p {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 48px;
  margin-top: 12px;
}
.feedback-form__notice b {
  display: flex;
  align-items: center;
  gap: 2px;
}
.feedback-form__notice b i {
  line-height: 12px;
  font-size: 12px;
}
.feedback-form__links {
  text-align: right;
  margin-top: 12px;
}
.feedback-form__links p {
  font-family: "PF DinText Light", sans-serif;
  font-size: 12px;
}
.feedback-form__links a:hover {
  text-decoration: underline;
}

.page-header {
  margin-bottom: 80px;
}
.page-title {
  font-size: 56px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.page-update {
  font-family: "PF DinText Thin", sans-serif;
  font-size: 20px;
}
.page-description {
  padding-left: 180px;
  margin-bottom: 60px;
}
.page-description p {
  font-size: 30px;
  font-family: "PF DinText Light", sans-serif;
  line-height: 1.2;
}

.section-page {
  padding: 100px 0;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}

.error-page {
  background-color: #000;
  height: 100vh;
  color: #fff;
}

.section-page-error {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 100%;
  padding: 180px 0;
}

.page-text {
  padding-top: 156px;
  padding-bottom: 60px;
}
.page-text-content {
  max-width: 1000px;
  margin: auto;
}
.page-text-footer {
  position: relative;
  z-index: 11;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-end;
  padding-bottom: 24px;
}
.page-text-footer .copyright,
.page-text-footer .back-to-top {
  opacity: 1;
  visibility: visible;
}
.page-text-footer .back-to-top {
  justify-content: center;
}

.page-content {
  max-width: 640px;
  margin: 0 auto;
  color: #141D00 !important;
  font-family: "PF DinText Regular";
  font-weight: 300;
}
.page-content h4 {
  font-family: "PF DinText Light", sans-serif;
  font-size: 20px;
}
.page-content p {
  font-size: 20px;
  font-family: "PF DinText Light", sans-serif;
  line-height: 1.2;
  margin-bottom: 24px;
}
.page-content ul {
  margin-bottom: 60px;
}
.page-content ul li {
  font-size: 20px;
  font-family: "PF DinText Light", sans-serif;
  line-height: 1.2;
}
.page-content .page-description p {
  font-size: 32px;
  font-weight: 300;
  line-height: 38.4px;
  text-align: left;
}
.page-content h5 {
  font-family: "PF DinText Regular", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-transform: none;
  padding-top: 40px;
}
.page-content b {
  font-family: "PF DinText Regular", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.page-content a, .page-content ol li {
  color: #0E008E;
  text-decoration: underline;
  font-family: "PF DinText Light", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
}
.page-content .chapter h5 {
  font-family: "PF DinText Regular", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-bottom: 24px;
  padding-top: 0;
}
.page-content .chapter ul {
  margin-bottom: 20px;
}
.page-content .chapter {
  padding-bottom: 40px;
}
.page-content table td {
  font-family: "PF DinText Light", sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
}
.page-content table tr:nth-child(1) td {
  font-family: "PF DinText Regular", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.page-content table td {
  vertical-align: top;
  padding: 25px;
}
.page-content table td:nth-child(3) {
  text-align: right;
}
.page-content .addr {
  font-family: "PF DinText Light", sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
}
.page-content p {
  /* font-family: "PF DinText Pro", sans-serif; */
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
}

.error-message {
  margin-left: 200px;
}
.error-message h3 {
  display: flex;
  flex-direction: column;
  font-family: "PF DinText Regular", sans-serif;
  text-transform: uppercase;
  margin-bottom: 40px;
}
.error-message h3 span {
  font-family: "PF DinText Thin", sans-serif;
}
.error-message a {
  text-transform: uppercase;
  text-decoration: underline;
  font-family: "PF DinText Thin", sans-serif;
}
.error-message a:hover {
  text-decoration: none;
}

.static-page .cookies__wrapper {
  background-color: #FFFF92;
}

#logo {
  position: absolute;
  left: calc(50vw - 130px);
  top: calc(50vh - 56px);
  width: 260px;
  height: 112px;
}

.slider-main {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px 120px;
}

.mobile-nav {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: space-between;
}
.mobile-nav__wrapper {
  position: fixed;
  top: 0;
  right: -80%;
  width: 80%;
  height: 100%;
  background-color: #000000;
  color: white;
  transition: right 0.3s ease;
  z-index: 20;
  padding: 24px;
}
.mobile-nav__wrapper .header-contacts {
  margin-top: 60px;
}
.mobile-nav__wrapper .header-contacts, .mobile-nav__wrapper .header-nav, .mobile-nav__wrapper .footer-links {
  display: flex;
  align-items: flex-start;
}
.mobile-nav__wrapper .header-contacts span, .mobile-nav__wrapper .header-nav span, .mobile-nav__wrapper .footer-links span {
  font-family: "PF DinText Thin", sans-serif;
}
.mobile-nav__wrapper .socials__link {
  display: flex;
  color: #FFFFFF;
}
.mobile-nav__footer {
  margin-bottom: 60px;
}
.mobile-nav__footer .copyright {
  opacity: 1;
  visibility: visible;
  color: #FFFFFF;
  gap: 20px;
}
.mobile-nav__footer .socials {
  display: flex;
  gap: 12px;
  font-size: 24px;
  align-items: flex-end;
  justify-content: flex-end;
}
.mobile-nav__footer .copyright-links {
  gap: 4px;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
}
.mobile-nav__footer .copyright-text {
  text-align: right;
}

.mobile-nav__opener {
  display: none;
  width: 20px;
  height: 20px;
}

.burger-menu__closer {
  position: absolute;
  right: 0;
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.mobile-menu__item {
  display: flex;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.mobile-menu-link {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.mobile-menu-link i {
  transform: rotate(90deg);
}